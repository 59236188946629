(() => {
  const filterNullValuesFromObject = object => Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null && v !== '' && typeof v !== 'undefined'));

  const mapProperties = object => ({
    company_id: 'anonymous_identified_' + object.company.domain,
    company_domain: object.company.domain,
    company_name: object.company.name,
    company_annual_revenue: parseInt(object.company.annual_revenue),
    company_country: object.company.country,
    company_number_of_employees: parseInt(object.company.employee_count),
    company_industry: object.company.industry,
    '6sense__confidence': object.confidence,
    ...(
      object.scores[0] ?
      {
        '6sense__buying_stage': object.scores[0].buying_stage,
        '6sense__intent_score': object.scores[0].intent_score,
        '6sense__profile_score': object.scores[0].profile_score,
        '6sense__profile_fit': object.scores[0].profile_fit
      } :
      {}
    )
  });

  const processIdentify = object => {
    const identityObject = filterNullValuesFromObject(mapProperties(object));
    if (!identityObject['company_domain']) return;

    window.HockeyStack.addSharedProperty({
      key: 'company_id',
      value: identityObject.company_id,
      properties: {
        ...identityObject,
        is_crm_object: false,
        is_anonymous_identified: true
      }
    });
  }

  const getData = () => {
    const companyObjectStringified = localStorage._6senseCompanyDetails;

    if (!(timeout > 5000 || localStorage._6senseCompanyDetails)) {
      timeout += 50;
      setTimeout(getData, 50);
      return;
    }

    try {
      const companyObject = JSON.parse(companyObjectStringified);

      if (companyObject.company) {
        if (window.HockeyStack) return processIdentify(companyObject);

        if (!Array.isArray(window.hockeystackQueue)) {
          window.hockeystackQueue = [];
        }
        window.hockeystackQueue.push(() => processIdentify(companyObject));
      }
    } catch (error) {}
  };

  let timeout = 0;
  getData();
})();
